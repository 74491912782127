import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import "../../styles/style.css"
import Footer from './Footer'
import Header from './Header'
import HeadLink from "../Utility/HeadLink"

function Layout({children}) {
    return (
      <div>
        <HeadLink />
        <Header />
        {children}
        <Footer />
      </div>
    )
}

export default Layout
